import React from "react";
import Header from "./Header";
import UsableSiderbar from "./UsableSiderbar";

const Donate = () => {
  return (
    <>
      <Header />
      <div className="bg-[#f7f7f7] w-full">
        <p className='text-[25px] leading-[25px] text-[#888888] p-5 font-bold font-["Patua One", Arial, Tahoma, sans-serif]'>
          Donate
        </p>
        <div className="w-full h-[auto] lg:flex justify-start items-start border-t-[1px] border-opacity-20 border-[#616161]">
          <div className="w-full lg:w-[65%] h-[100%] bg-[#fcfcfc] px-5 py-6 lg:order-2">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center font-bold">
              Donate
            </p>
            {/* <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              The Baitul Salaam Network, Inc. a non-profit through our Outreach
              office distributes food (the Islamic Food Pantry), counsels, makes
              referrals, intake for housing and rent payment assistance two days
              per week. The staff members are on small stipends (Administrator
              $800 per month, Outreach Assistant $300 per month). Our office
              rent is $375 per month. Our landlord is the Family Life Center
              located in Decatur, GA. Our office supplies, printing and other
              miscellaneous cost average $125.00 per month, in shaa Allah.
            </p> */}
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Zakat and Sadaaqah is used to assist with emergency, transitional
              and rent assistance. Our goal monthly is to raise $6,500 to assist
              people who need help in these areas. It can be done without anyone
              missing out on anything for themselves and local community
              projects, insh’Allah. Remember our numbers when we remember this
              we can do a lot more good than we realize. Just think 1,500 giving
              just $8.00 per month will do a lot to help us meet and surpass our
              goal, in shaa Allah.
            </p>
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center mt-3"> You can donate to our cause to our paypal account: <span className='text-[#2991d6] hover:text-[#2275ac]'>haleem1@aol.com</span>
</p>
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center mt-3">
              We appreciate your support.
            </p>
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start">
              By US Mail:
            </p>
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Baitul Salaam Network, Inc.
            </p>
            {/* <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
          3300 Memorial Dr
          </p>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
          Suite 5
          </p>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
          Stone Mountain, GA 30083
          </p> */}
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              PO Box 115470
            </p>
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Atlanta, GA 30310
            </p>
            {/* <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Attn: Bilal Stroud
            </p> */}
          </div>
          <UsableSiderbar/>
          <div className="w-full lg:w-[15%] h-[100%] flex justify-start lg:justify-center items-start px-5 order-3 lg:order-3">
            <div className="w-[40%] lg:w-full border-t-[4px] border-x-[1px] border-b-[1px] border-[#626262] border-opacity-20 mt-10 mb-10 lg:mb-0">
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://t0.gstatic.com/images?q=tbn:ANd9GcRPd525uKPHBXmuNjMgqzKP2J5fLgq44AShF5PE7CrOCYlfeETp"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQtCCRc7d79y0uD3GCCj-6DHTnV8EySlUrd7xhBaz4Iz-yBnuEX"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center px-3">
                <img
                  src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSsT-qQRtK_AqLqETeP7qNIzA3e6YIRGOeLUXwY1Q4W3hyJVrKJ"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;
