import React, { useRef } from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const closeRef = useRef()
  const handleCloser = () => {
    setIsSidebarOpen(false)
  }
  console.log(isSidebarOpen);
  // console.log(true)
  return (
    <div className={`fixed top-0 left-0 w-full h-full z-10 flex justify-start items-start transform transition-transform duration-300 ${
      isSidebarOpen ? "translate-x-0" : "-translate-x-full"
    }`}>
      <div
        className={`h-full w-64 p-4 z-20 bg-gray-800 opacity-100`}
      >
        <ul className="text-white">
          <Link to={"/"}>
            <li className="w-fit py-1 hover:border-b-[1px] hover:border-[#fff] transform transition-transform duration-1000 cursor-pointer">
              Home
            </li>
          </Link>
          <Link to={"/about"}>
            <li className="w-fit py-1 hover:border-b-[1px] hover:border-[#fff] transform transition-transform duration-1000 cursor-pointer">
              About
            </li>
          </Link>
          <Link to={"/donate"}>
            <li className="w-fit py-1 hover:border-b-[1px] hover:border-[#fff] transform transition-transform duration-1000 cursor-pointer">
              Donate
            </li>
          </Link>
          <Link to={"/resources"}>
            <li className="w-fit py-1 hover:border-b-[1px] hover:border-[#fff] transform transition-transform duration-1000 cursor-pointer">
              Resources
            </li>
          </Link>
          <Link to={"/photos"}>
            <li className="w-fit py-1 hover:border-b-[1px] hover:border-[#fff] transform transition-transform duration-1000 cursor-pointer">
              Photos
            </li>
          </Link>
          <Link to={"/contact"}>
            <li className="w-fit py-1 hover:border-b-[1px] hover:border-[#fff] transform transition-transform duration-1000 cursor-pointer">
              Contact
            </li>
          </Link>
        </ul>
      </div>
      <div className="w-full h-full" onClick={handleCloser}></div>
    </div>
  );
};

export default Sidebar;
