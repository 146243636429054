import React from "react";
import Header from "./Header";
import UsableSiderbar from "./UsableSiderbar";

const About = () => {
  return (
    <>
      <Header />
      <div className="bg-[#f7f7f7] w-full">
        <p className='text-[25px] leading-[25px] text-[#888888] p-5 font-bold font-["Patua One", Arial, Tahoma, sans-serif]'>
          About Us
        </p>
        <div className="w-full h-[auto] lg:flex justify-start items-start border-t-[1px] border-opacity-20 border-[#616161]">
          <div className="w-full lg:w-[65%] h-[100%] bg-[#fcfcfc] px-5 py-6 order-1 lg:order-2">
            <div className="">
              <p className="font-patua font-bold text-[#444444] text-[30px] leading-[30px] text-center">
                Our Mission Statement
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center mt-3">
                Our goal is to serve as a support mechanism for individuals and
                other organizations who assist survivors of domestic abuse and
                sexual assault.
              </p>
            </div>
            <div className="mt-4">
              <p className="font-patua font-bold text-[#444444] text-[30px] leading-[30px] text-center">
                Our Vision Statement
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center mt-3">
                We want to help reshape the thinking in our world where the
                thought of using violence to resolve conflicts is no longer an
                option.
              </p>
            </div>
            <div className="mt-4">
              <p className="font-patua font-bold text-[#444444] text-[30px] leading-[30px] text-center">
                Who We Are
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center mt-3">
                Baitul Salaam Network International, Inc. (BSN) is a nonprofit
                domestic violence awareness organization. The term Baitul Salaam
                means “House of Peace” in the Arabic language. BSN is designed
                to be a refuge for victims of domestic violence who are
                experiencing danger and distress in their own homes. In the
                Islamic month of Ramadan in 1997, BSN formed with a group of
                four Muslim women who were all experiencing some form of
                domestic violence. These women were able to overcome their
                circumstances by joining together, supporting each other, and
                finding purpose in being able to help others who were
                confronting the same challenges. While BSN does not limit its
                services to Muslim women, it does have a special sensitivity to
                those of the Islamic faith. We understand the importance of
                working within the Islamic guidelines for clients who require
                it.
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-center mt-3">
                BSN is open to helping anyone who requests help. We do not
                judge. We are a resource for victims of domestic violence as
                well as those who are experiencing financial hardship and need
                assistance.
              </p>
            </div>
            {/* Patua One", Arial, Tahoma, sans-serif */}
          </div>
          <UsableSiderbar/>
          <div className="w-full lg:w-[15%] h-[100%] flex justify-start lg:justify-center items-start px-5 order-3 lg:order-3">
            <div className="w-[40%] lg:w-full border-t-[4px] border-x-[1px] border-b-[1px] border-[#626262] border-opacity-20 mt-10 mb-10 lg:mb-0">
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://t0.gstatic.com/images?q=tbn:ANd9GcRPd525uKPHBXmuNjMgqzKP2J5fLgq44AShF5PE7CrOCYlfeETp"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQtCCRc7d79y0uD3GCCj-6DHTnV8EySlUrd7xhBaz4Iz-yBnuEX"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center px-3">
                <img
                  src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSsT-qQRtK_AqLqETeP7qNIzA3e6YIRGOeLUXwY1Q4W3hyJVrKJ"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
