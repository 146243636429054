import React from "react";
import Header from "./Header";
import UsableSiderbar from "./UsableSiderbar";

const Contact = () => {
  return (
    <>
      <Header />
      <div className="bg-[#f7f7f7] w-full">
        <p className='text-[25px] leading-[25px] text-[#888888] p-5 font-bold font-["Patua One", Arial, Tahoma, sans-serif]'>
          Contact
        </p>
        <div className="w-full h-[auto] lg:flex justify-start items-start border-t-[1px] border-opacity-20 border-[#616161]">
          <div className="w-full lg:w-[60%] h-[100%] bg-[#fcfcfc] px-5 py-6 lg:order-2">
            <div className="">
              <p className="font-patua font-bold text-[#444444] text-[30px] leading-[30px] text-start">
                Our Staff
              </p>
            </div>
            <div className="mt-10">
              <p className="font-patua font-bold text-[#444444] text-[30px] leading-[30px] text-start">
                Volunteer Opportunities
              </p>
            </div>
            <div className="mt-20">
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                Please mail all donations to:
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                Baitul Salaam Network, Inc.
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                PO Box 115470
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                Atlanta, GA 30310
              </p>
              {/* <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                Attn: Bilal Stroud
              </p> */}
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                Phone:404-259-4279
              </p>
              <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
                Email: General@baitulsalaam.org
              </p>
            </div>
            {/* Patua One", Arial, Tahoma, sans-serif */}
          </div>
          <UsableSiderbar/>
          <div className="w-full lg:w-[15%] h-[100%] flex justify-start lg:justify-center items-start px-5 order-3 lg:order-3">
            <div className="w-[40%] lg:w-full border-t-[4px] border-x-[1px] border-b-[1px] border-[#626262] border-opacity-20 mt-10 mb-10 lg:mb-0">
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://t0.gstatic.com/images?q=tbn:ANd9GcRPd525uKPHBXmuNjMgqzKP2J5fLgq44AShF5PE7CrOCYlfeETp"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQtCCRc7d79y0uD3GCCj-6DHTnV8EySlUrd7xhBaz4Iz-yBnuEX"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center px-3">
                <img
                  src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSsT-qQRtK_AqLqETeP7qNIzA3e6YIRGOeLUXwY1Q4W3hyJVrKJ"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
