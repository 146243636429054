import React from 'react'

const Footer = () => {
  return (
    <div className='w-full p-5 bg-[#545454]'>
        <p className="text-[13px] leading-[21px] text-[#fff] font-sans">
        © 2023 Baitul Salaam. All Rights Reserved. <a href='https://muffingroup.com' target='_blank'><span className='text-[#2991d6] hover:text-[#2275ac]'>Muffin group</span></a>
          </p>
    </div>
  )
}

export default Footer