import React from "react";
import Header from "./Header";
import UsableSiderbar from "./UsableSiderbar";

const Home = () => {
  return (
    <>
      <Header />
      <div className="bg-[#f7f7f7] w-full">
        <p className='text-[25px] leading-[25px] text-[#888888] p-5 font-bold font-["Patua One", Arial, Tahoma, sans-serif]'>
          Home
        </p>
        <div className="w-full h-[auto] lg:flex justify-start items-start border-t-[1px] border-opacity-20 border-[#616161]">
          <div className="w-full lg:w-[65%] h-[auto] bg-[#fcfcfc] px-5 py-6 order-1 lg:order-2">
            {/* <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              The Baitul Salaam Network, Inc. a non-profit domestic violence
              outreach program dedicated to assisting women find safe places to
              go when they are in unsafe situations specializes in working with
              women of the Islam faith. If you need some place to go we can
              assist in helping you find a safe place to go call Hadayai Majeed
              at 770-255-8500. If she does not answer text her at that number.
            </p> */}
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              The Baitul Salaam Network, Inc. a non-profit organization. We
              counsel, makes referrals for housing, and assist with giving funds
              to help people pay rent, emergency housing, gas money and bus
              fare. We are all volunteer at this time and network with other
              organizations to provide services to those who come to us. Office
              supplies, printing, telephone and other miscellaneous cost average
              $50 per month, in shaa Allah.
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              Zakat and Sadaaqah is used to assist with emergency, transitional
              housing and rent assistance. Our goal monthly is to raise a
              minimum of $5,000 to assist people who need help in these areas.
              It can be done without anyone missing out on anything for
              themselves and local community projects, insh’Allah. Remember our
              numbers when we remember this, we can do a lot better than we
              realize. Just think 1,000 giving just $10 per month will do a lot
              to help us meet and surpass our goal, in shaa Allah.
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              We appreciate your support.
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              By US Mail:
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              Baitul Salaam Network, Inc.
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              PO Box 115470
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              Atlanta, GA 30310
            </p>
            <br />
            <p className="text-[13px] leading-[21px] text-[#626262] font-sans">
              Attn: Administrator
            </p>
            {/* <p className="text-[13px] leading-[21px] text-[#626262] font-sans mt-2">
              {" "}
              Due to financial constraints we no longer have our shelter and
              food distribution program.{" "}
            </p> */}
          </div>
          <UsableSiderbar />
          <div className="w-full lg:w-[15%] h-[100%] flex justify-start lg:justify-center items-start px-5 order-3 lg:order-3">
            <div className="w-[40%] lg:w-full border-t-[4px] border-x-[1px] border-b-[1px] border-[#626262] border-opacity-20 mt-10 mb-10 lg:mb-0">
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://t0.gstatic.com/images?q=tbn:ANd9GcRPd525uKPHBXmuNjMgqzKP2J5fLgq44AShF5PE7CrOCYlfeETp"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQtCCRc7d79y0uD3GCCj-6DHTnV8EySlUrd7xhBaz4Iz-yBnuEX"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center px-3">
                <img
                  src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSsT-qQRtK_AqLqETeP7qNIzA3e6YIRGOeLUXwY1Q4W3hyJVrKJ"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
