import React from "react";
import ReactDOM from "react-dom";
// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

const UsableSiderbar = () => {
  return (
    <div className="w-full lg:w-[20%] pr-[20px] order-2 lg:order-1">
      <div className="px-5">
        <h2>Donations</h2>
        <div className=" p-5">
          {/* <img
            src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
            alt="payment-gif"
          /> */}
          <ul className="m-0 p-0">
            <li>
              <label className="text-red-500">$Cashapp </label>
              <p>$baitulsalaam1</p>
            </li>
            <hr className=" border-[1px] border-[#616161] w-full mt-[20px] mb-[20px] opacity-20" />
            <li>
              <label className="text-red-500">PayPal</label>
              <p>haleem1@aol.com</p>
            </li>
          </ul>
        </div>
        {/* <hr className=" border-[1px] border-[#616161] w-full mt-[20px] opacity-20" /> */}
      </div>
      {/* <div>
        <div className="mt-[10px] p-5">
          <img
            src="https://www.baitulsalaam.org/wp-content/uploads/2016/04/iGive.jpg"
            alt="payment-gif"
          />
        </div>
        <hr className=" border-[1px] border-[#616161] w-full mt-[20px] opacity-20" />
      </div> */}
      {/* <div>
        <div className="mt-[10px] p-5">
          <img
            src="https://www.baitulsalaam.org/wp-content/uploads/2016/04/ZazzleLogo.png"
            alt="payment-gif"
          />
        </div>
        <hr className=" border-[1px] border-[#616161] w-full mt-[20px] opacity-20" />
      </div> */}
    </div>
  );
};

export default UsableSiderbar;
