import React from "react";
import { Link } from "react-router-dom";
import baitulSalaam from "../assets/images/baitulsalaam.jpg"

const Navbar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  console.log(isSidebarOpen);
  const currentPath = window.location.pathname;
  // alert(currentPath)

  return (
    <div className="px-2.5 ">
      <div className="flex justify-between lg:justify-center items-center gap-3 lg:gap-0 p-4">
        <div className="text-[#2c2c2c]] font-bold text-xl w-full lg:w-1/2 h-40 lg:h-52">
          <img
            src={baitulSalaam}
            alt="your logo"
            className="h-full w-full"
          />
        </div>
        <button
          className="lg:hidden px-2 py-1 rounded-md text-white hover:bg-gray-700"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="#2991d6"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
      <div className={`hidden lg:flex justify-center items-center w-full border-t-[2px] border-opacity-20 border-[#626262]`}>
        <Link to={'/'}><div className={`font-[Roboto, Arial, Tahoma, sans-serif] text-[14px] ${currentPath === '/' ? "text-[#2991d6]" : "text-[#626262]"} hover:text-[#2991d6] cursor-pointer p-4 border-r-[1px] border-[#626262] border-opacity-20`}><span>Home</span></div></Link>
        <Link to={'/about'}><div className={`font-[Roboto, Arial, Tahoma, sans-serif] text-[14px] ${currentPath.includes('about')? "text-[#2991d6]" : "text-[#626262]"} hover:text-[#2991d6] cursor-pointer p-4 border-r-[1px] border-[#626262] border-opacity-20`}><span>About Us</span></div></Link>
        <Link to={'/donate'}><div className={`font-[Roboto, Arial, Tahoma, sans-serif] text-[14px] ${currentPath.includes('donate') ? "text-[#2991d6]" : "text-[#626262]"} hover:text-[#2991d6] cursor-pointer p-4 border-r-[1px] border-[#626262] border-opacity-20`}><span>Donate</span></div></Link>
        <Link to={'/resources'}><div className={`font-[Roboto, Arial, Tahoma, sans-serif] text-[14px] ${currentPath.includes('resources') ? "text-[#2991d6]" : "text-[#626262]"} hover:text-[#2991d6] cursor-pointer p-4 border-r-[1px] border-[#626262] border-opacity-20`}><span>Resources</span></div></Link>
        <Link to={'/photos'}><div className={`font-[Roboto, Arial, Tahoma, sans-serif] text-[14px] ${currentPath.includes('photos') ? "text-[#2991d6]" : "text-[#626262]"} hover:text-[#2991d6] cursor-pointer p-4 border-r-[1px] border-[#626262] border-opacity-20`}><span>Photos</span></div></Link>
        <Link to={'/contact'}><div className={`font-[Roboto, Arial, Tahoma, sans-serif] text-[14px] ${currentPath.includes('contact') ? "text-[#2991d6]" : "text-[#626262]"} hover:text-[#2991d6] cursor-pointer p-4`}><span>Contact</span></div></Link>
      </div>
    </div>
  );
};

export default Navbar;
