import React from "react";
import Header from "./Header";
import UsableSiderbar from "./UsableSiderbar";

const Resources = () => {
  return (
    <>
    <Header />
    <div className="bg-[#f7f7f7] w-full">
      <p className='text-[25px] leading-[25px] text-[#888888] p-5 font-bold font-["Patua One", Arial, Tahoma, sans-serif]'>
        Resources
      </p>
      <div className="w-full h-[auto] lg:flex justify-start items-start border-t-[1px] border-opacity-20 border-[#616161]">
      <div className="w-full lg:w-[60%] h-[100%] bg-[#fcfcfc] px-5 py-6 lg:order-2">
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            RESOURCES
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              The <span className="font-bold">Baitul Salaam Network, Inc.</span>{" "}
              offers{" "}
              <span className="font-bold">
                pre-abortion and post abortion counseling.
              </span>{" "}
              No pressure and no judgement. Just facts and information. You do
              not have to disclose your identity.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            1 in 6
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              1 in 6 men have had an unwanted sexual experience by the time they
              are 18 in the US. Please visit www.1in6.org to seek help or to
              offer help.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Atlanta Bar Association: Lawyer Referral Service
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              The Atlanta Bar Association Lawyer Referral Service helps people
              find lawyers suited to their particular type of legal problems.
              Participating lawyers have indicated the area(s) of law in which
              they can help clients, and in which counties. The Lawyer Referral
              Service provides clients with the name, address, and phone number
              of an attorney who is located in a convenient geographic area, who
              will provide 30 minutes of legal consultation for $35. The client
              and attorney will be able to discuss the case, concerns, or legal
              issues. The client must negotiate a fee with the attorney for any
              legal services beyond the initial consultation. The Atlanta Bar
              Association, 229 Peachtree Street, N.E., 400 International Tower
              Atlanta, GA 30303. Office: (404) 521-0777
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Black Dignity
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#2991d6] hover:text-[#2275ac] text-[13px] leading-[21px] text-start mt-3">
              <a href="https://blackdignity.org/" target="_blank">
                https://blackdignity.org/
              </a>
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Provides consultation and information regarding pregnancy and
              abortion
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Bridging The Gap
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Tariq I. El-Amin, is an active member of the Muslim community in
              Chicago. He is dedicated to addressing the concerns of Muslims in
              the U.S., with a special focus on youth development. His varied
              memberships and works are directed towards that goal. Tariq is an
              appointee to Illinois Governor Pat Quinn’s Muslim Advisory
              Council. He is the Convener of the Chicagoland Shura Council; a
              long time Director of the Society of United Neighbors (S.U.N.),
              and also is the founder and Executive Director of Bridging The
              Gap, Inc, a non for profit organization with an established
              Islamic weekend school, mentoring program and frequent youth led
              service projects.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Building Blocks of New Jersey
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Building Blocks of New Jersey is a 501(c)(3) nonprofit
              organization. Building Blocks provides a platform for all
              concerned Muslims to practically apply Islamic principles and work
              together for the community’s advancement through the provision of
              social, educational, and religious services, as well as outreach
              programs to build bridges of mutual understanding with other
              communities. Building Blocks serves all people regardless of race,
              ethnicity, religion, creed or any other discriminatory factor
              prohibited by law.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Covenant House
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Covenant House was founded in 1972 with the mission to help
              homeless youth escape the streets. Today, Covenant House is the
              largest privately-funded agency in the Americas providing food,
              shelter, immediate crisis care, and essential services to
              homeless, throwaway, and runaway kids.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Custodians of Faith
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Weekly Hot Food Feeding Program
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              PO Box 29924
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Atlanta, GA 30339
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Rasheeda El, CEO, RasheedahEl@att.net
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Doula-Feale Servant
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Personal Services for Mothers and their families!
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              LaVaon Rose
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Postpartum Doula
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Smyrna, GA
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              404-542-5335
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Lavoan@live.com
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Doula Services
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Professional Childbirth Support
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Michela Towne
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              DONA Certified Doula
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              770-218-1648/309-8806
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            GainPeace
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              GainPeace Information on Islam or to order free Qur’an and
              literature: Call 800-662-ISLAM or visit GainPeace Know Islam on
              TV: Get DeenShow or GuideUS TV To reach to me directly: call
              402-500-0843 or 530-ISLAM-11 Confused or curious about Shari’a:
              call 1-855-SHARIA
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Giving Back to Humanity
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Giving Back to Humanity Giving Back to Humanity is a non-profit,
              501(c)(3), organization based in Atlanta, Georgia. We have
              expanded beyond our original goals and continue to grow. We have
              had three successful years of providing food to approximately 400
              clients on a weekly basis.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Famaco Publishers, LLC
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              6001-21 Argyle Forest Blvd. 323
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Jacksonville, Florida 32244-6127
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Tel: (905) 434-5901
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Fax: (904) 777-5901 Email: sales@famacopublishers.com
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Madinah Food Pantry
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Muslim operated food pantry that distributes food, clothing, and
              household items for people who come to the pantry. We also have a
              mobile food pantry that serves the northeast Georgia community and
              metro Atlanta and surrounding counties. Please contact Dr. Salima
              Hinafa at 706-224-0924 or ummisix@gmail.com for further
              information or assistance.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Millati Islami World Services Organization Inc.
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              PO Box 2100 Douglasville GA 30133
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              www.milliislami.org
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              – Ameen Rasheed 215.514.6692
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              ameen@millatiislami.org
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              – Islah Ali 404.254.1891
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              islah@millatiislami.org
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              – Jamal El-Amin 412.913.559
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              jamale1-amin@millatiislami.org
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              – Niles Akbar 773.610.3517
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              niles@millattiislami.org
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Motherly Love Child Care
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Motherly Love is an in-Home family day care committed to serving
              the needs of all children utilizing a hands-on approach to
              learning. In doing so, children will be given the opportunity to
              interact with their environment in a physical manner. Motherly
              Love will implement this strategy by utilizing skill developmental
              toys, such as blocks, puzzles, books, and other tangible objects
              that children can relate to, in order to help them grow, develop,
              and learn in a more interactive way. Each day, Motherly Love will
              provide creative learning experiences and supervised activities
              for each child’s development and enjoyment. Tel: (770) 744-1603
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Muslimah Source
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              www.MuslimahSource.org was started by a group of friends with the
              intention of serving as a resource and as a tool to empower Muslim
              women. What do we mean by empowerment? We mean helping women
              embrace and demand the rights guaranteed them by their Creator
              through the religion of Islam, building the capacity of Muslim
              women to engage their communities, and helping them overcome
              barriers to greater empowerment.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Muslim Legal Fund of America
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              The Muslim Legal Fund of America (“MLFA”) was established in
              November 2001 by a coalition of civil rights activists seeking to
              redress systemic discrimination against Arabs, Muslims, and South
              Asians. While these communities have historically not been immune
              from discrimination, the horrific terrorist attacks of September
              11, 2001 caused a surge in discrimination, hate crimes, and
              unlawful profiling by both public and private actors.
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              <span className="font-bold">Spiritual Biz Moms</span> Fatima Omar
              Khamissa Direct Line: 416-721-0994 Website:
              www.FatimaOmarKhamissa.com Website: www.GodAndEnergy.com Twitter:
              @FatimaKhamissa Twitter: @SpiritualBizMom Facebook: Fatima Omar
              Khamissa
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Texas Muslim Women’s Foundation
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              To empower, promote, and support Women and their Families through
              Educational, Outreach, Charitable, and Social Services.
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Victoria N. Tawwab CD, B/P, AMP
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Certified Wholistic Doula
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              678-558-6046
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Womban’s Right
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Brandi Shamsid-Deen, Birth Doula
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Brandie@wombansright.com
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              404-913-9662
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            HEAL – Health Education, Assessment & Leadership , Inc.
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Dr. Zenobia Day – Family Practioner
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              2600 MLK Dr. Atlanta 30311
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              404-564-7749
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            Flat Shoals Internal Medicine
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Carmen Echols, M.D.
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              4153-B Flat Shoals Parkway, Suite 200
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Decatur, GA 30034
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              404-585-5049
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            1 in 3
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              1 in 3 Women will have an abortion in her lifetime. These are our
              stories.
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              ICADV-Indiana Coalition Against Domestic Violence
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Prevention * Advocacy * Awareness Until the Violence Ends
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#2991d6] hover:text-[#2275ac] text-[13px] leading-[21px] text-start mt-3">
              <a href="https://www.icadvinc.org/" target="_blank">
                www.icadvinc.org/
              </a>
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Voice 800.332.7385
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              TTY 800.787.3224
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
            JDC Help Center
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              2041 Metropolitan Pkwy
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Atlanta, GA
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              404-228-9753
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
              Get help with almost anything you need such as utilities, rent,
              bus cards, food medical bills call to set appt. They serve 23
              counties.
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
          Sarah’s Closet
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            5285 Flat Shoals Parkway (rear building)
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Decatur, GA 30034
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            We have a food pantry and gently used clothing to assist the communities within:
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            30032, 30034, 30035, 30087 and 30088
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Hrs: T, Th 11:30-1:30 p.m. And 5:30-7:30 p.m.
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Director: Mrs. Mary Davison
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Alphonsemary@bellsouth.net
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
          Atlanta Volunteer Lawyers
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            MAC Family Center, PC
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            2336 Wisteria Drive
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Suite 230
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Snellville GA 30078
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
          The Secret Firm, P.C.
          </p>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Akil Secret, Esq.
            </p>
          </div>
          <div className="mt-4">
            <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3">
            Attorney
            </p>
          </div>
          <p className="font-roboto text-[#444444] text-[13px] leading-[21px] text-start mt-3 font-bold">
          404-524-5300
          </p>
          {/* Patua One", Arial, Tahoma, sans-serif */}
        </div>
        <UsableSiderbar/>
        <div className='w-full lg:w-[15%] h-[100%] flex justify-start lg:justify-center items-start px-5 order-3 lg:order-3'>
            <div className='w-[40%] lg:w-full border-t-[4px] border-x-[1px] border-b-[1px] border-[#626262] border-opacity-20 mt-10 mb-10 lg:mb-0'>
                <div className='w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3'>
                    <img src='https://t0.gstatic.com/images?q=tbn:ANd9GcRPd525uKPHBXmuNjMgqzKP2J5fLgq44AShF5PE7CrOCYlfeETp' className='w-[60%] h-[80%]' alt='media-gif' />
                </div>
                <div className='w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3'>
                    <img src='https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQtCCRc7d79y0uD3GCCj-6DHTnV8EySlUrd7xhBaz4Iz-yBnuEX' className='w-[60%] h-[80%]' alt='media-gif' />
                </div>
                <div className='w-full h-[100px] flex justify-center items-center px-3'>
                    <img src='https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSsT-qQRtK_AqLqETeP7qNIzA3e6YIRGOeLUXwY1Q4W3hyJVrKJ' className='w-[60%] h-[80%]' alt='media-gif' />
                </div>
            </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Resources;
