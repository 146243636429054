import React from "react";
import Parent from "./components/Parent";

function App() {
  return (
    <div className="bg-[#a915c8] h-full flex justify-center items-center pt-6 text-roboto">
        <div className="bg-[#2c2c2c] w-4/5 h-full pt-6">
          <Parent />
        </div>
    </div>
  );
}

export default App;

// import React, { useState } from 'react';

// const DropdownMenu = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className={`relative w-fit bg-green-950 ${!isOpen ? 'overflow-hidden' : 'overflow-y-none'}`}>
//       <button
//         className="p-2 rounded-md bg-gray-500 text-white"
//         onClick={toggleMenu}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="h-6 w-6"
//           fill="none"
//           viewBox="0 0 24 24"
//           stroke="currentColor"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth={2}
//             d="M4 6h16M4 12h16M4 18h16"
//           />
//         </svg>
//       </button>
//         <div className={`absolute right-0 mt-2 w-48 bg-red-700 ${!isOpen ? '-translate-y-1' : 'translate-y-1'} overflow-hidden transform transition-transform duration-300  rounded-md shadow-lg`}>
//           <ul>
//             <li className="px-4 py-2">Menu Item 1</li>
//             <li className="px-4 py-2">Menu Item 2</li>
//             <li className="px-4 py-2">Menu Item 3</li>
//           </ul>
//         </div>
//       )
//     </div>
//   );
// };

// export default DropdownMenu;
