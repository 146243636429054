import React, { useState } from "react";
import Header from "./Header";
import Home from "./Home";
import About from "./About";
import Donate from "./Donate";
import Contact from "./Contact";
import Resources from "./Resources";
import Photos from "./Photos";
import Footer from "./Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Parent = () => {
  return (
    <div className="w-full h-full bg-white">
      {/* <ToastContainer > */}
        <Router>
          {/* <Donate /> */}
          {/* <Header/> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/photos" element={<Photos />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Router>
      <ToastContainer/>
    </div>
  );
};

export default Parent;
