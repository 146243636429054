import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "./Header";
import UsableSiderbar from "./UsableSiderbar";

const Photos = () => {
  const imageArray = [
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-18-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-17-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-16-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-15-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-14-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-13-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-12-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-11-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-10-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-9-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-8-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-7-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-6-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-5-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-4-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-3-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-2-200x100.jpg",
    "https://www.baitulsalaam.org/wp-content/uploads/2016/04/photo-1-200x100.jpg",
  ];
  return (
    <>
      <Header />
      <div className="bg-[#f7f7f7] w-full">
        <p className='text-[25px] leading-[25px] text-[#888888] p-5 font-bold font-["Patua One", Arial, Tahoma, sans-serif]'>
          Photos
        </p>
        <div className="w-full h-[auto] lg:flex justify-start items-start border-t-[1px] border-opacity-20 border-[#616161]">
          <div className="w-full lg:w-[65%] h-[100%] bg-[#fcfcfc] px-5 py-6 lg:order-2">
            <p className="font-patua font-bold text-[#444444] text-[30px] leading-[30px] text-start mb-5">
              Photo Gallery
            </p>
            <Carousel
              autoPlay={true}
              interval={3000}
              autoFocus={true}
              swipeable={true}
              infiniteLoop={true}
            >
              {imageArray.map((image, index) => (
                <div key={index} className="w-full h-[80%]">
                  <img
                    src={image}
                    alt="display items"
                    className="object-contain"
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <UsableSiderbar/>
          <div className="w-full lg:w-[15%] h-[100%] flex justify-start lg:justify-center items-start px-5 order-3 lg:order-3">
            <div className="w-[40%] lg:w-full border-t-[4px] border-x-[1px] border-b-[1px] border-[#626262] border-opacity-20 mt-10 mb-10 lg:mb-0">
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://t0.gstatic.com/images?q=tbn:ANd9GcRPd525uKPHBXmuNjMgqzKP2J5fLgq44AShF5PE7CrOCYlfeETp"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center border-b-[1px] border-[#626262] border-opacity-20 px-3">
                <img
                  src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQtCCRc7d79y0uD3GCCj-6DHTnV8EySlUrd7xhBaz4Iz-yBnuEX"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
              <div className="w-full h-[100px] flex justify-center items-center px-3">
                <img
                  src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSsT-qQRtK_AqLqETeP7qNIzA3e6YIRGOeLUXwY1Q4W3hyJVrKJ"
                  className="w-[60%] h-[80%]"
                  alt="media-gif"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Photos;
